import React, {useContext, useEffect} from 'react';
import "../css/SplashScreen.css";
import Preloader from "./Preloader";
import AppDataContext from "./context/AppDataContext";
import {Link} from "react-router-dom";
const SplashScreen = ({loaded360}) => {
    const { appData, setAppData} = useContext(AppDataContext);

    useEffect(() => {
        if(loaded360 && appData.loaded)
        {
            document.getElementById("splash-screen").classList.add("hide-splash")
        }

    },[loaded360,appData.loaded])

    return (
        <div id="splash-screen" className="splash-screen">

            <div className="splash-screen-container">
                <img src={require("../img/logo/logo-splash.svg")} width={350} />
                <div className="mt-4">
                    <Preloader color={"dark"}/>
                </div>

            </div>
            <Link to={{  pathname: "https://wizio.co.uk/services-wizio-interactive-marketing-technologies-bournemouth/wizio-plus/" }} target={"_blank"}  className={"black-wizio-btn"}><img src={require("../img/wizio.svg")} width={25} /></Link>
        </div>
    );
};

export default SplashScreen;